import React, { useEffect, useState } from "react";

const RenderTimeAnalysis = () => {
  const [renderTimes, setRenderTimes] = useState<number[]>([]);
  const [averageTime, setAverageTime] = useState<number>(0);

  useEffect(() => {
    const times: number[] = [];
    let totalTime = 0;

    const measureRender = () => {
      const startTime = performance.now();

      requestAnimationFrame(() => {
        const endTime = performance.now();
        const renderTime = endTime - startTime;

        times.push(renderTime);
        totalTime += renderTime;

        if (times.length <= 10) {
          setRenderTimes([...times]);
          setAverageTime(totalTime / times.length);
          measureRender();
        }
      });
    };

    measureRender();
  }, []);

  return (
    <div className="w-full max-w-2xl p-4 border rounded-lg shadow-sm">
      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">컴포넌트 렌더링 시간 분석</h2>
      </div>

      <div className="space-y-4">
        <div className="text-lg">
          평균 렌더링 시간: {averageTime.toFixed(2)}ms
        </div>

        <div>
          <div className="font-medium mb-2">최근 10회 렌더링 시간:</div>
          <div className="grid grid-cols-5 gap-2">
            {renderTimes.map((time, index) => (
              <div key={index} className="p-2 bg-gray-100 rounded text-center">
                {time.toFixed(2)}ms
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderTimeAnalysis;
