import React from "react";
import styled from "styled-components";

const HotKeyMenuWrapper = styled.div`
  width: 100%;
  padding: 16px 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .menus {
    padding: 0 16px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 12px;
    justify-items: center;
    justify-content: center;

    > .item {
      width: 80px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: background-color 0.2s ease-in-out;
      position: relative;
      transition: transform 0.1s ease;

      &:active {
        transform: scale(0.95);
      }

      > .background {
        width: 65px;
        height: 65px;
        border-radius: 17px;
        background: rgba(211, 211, 211, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        > .icon {
          width: 45px;
          height: 45px;
          object-fit: contain;
        }
      }

      > .title {
        pointer-events: none;
        margin-top: 6px;
        color: #000;

        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      > .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
        // background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        border: none;
        font-size: 15px;

        > .ribbon {
          border-radius: 5px;
          width: 75%;
          height: 18px;
          background: red;
          color: white;
          text-align: center;
        }
      }
    }
  }
`;

interface MenuItem {
  icon: string;
  title: string;
  onClick: () => void;
  process?: boolean;
}

interface HotKeyMenuProps {
  items: MenuItem[];
}

export const HotKeyMenu = React.memo(({ items }: HotKeyMenuProps) => {
  return (
    <HotKeyMenuWrapper>
      <div className="menus">
        {items.map((item, index) => (
          <div
            className="item"
            key={index}
            onClick={item.onClick}
            id={item.title}
          >
            <div className="background">
              <img src={item.icon} alt="icon" className="icon" loading="lazy" />
            </div>
            <span className="title">{item.title}</span>
          </div>
        ))}
      </div>
    </HotKeyMenuWrapper>
  );
});
