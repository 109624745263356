// Banner.tsx
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import ImageContainer from "../../../components/common/ImageContainer";
import styled from "styled-components";

const BannerWrapper = styled.div<{ bg?: string }>`
  width: 100%;

  .banner {
    position: relative;
    width: 100%;
  }

  .banner img {
    width: 100%;
  }

  .banner::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 70.38%,
      #fff 98.41%
    );
    pointer-events: none;
  }
`;

interface BannerProps {
  data: Array<{
    url: string;
    description: string;
    backgroundColor?: string;
  }>;
  onBannerClick: (description: string) => void;
  currentIndex: number;
  onIndexChange: (index: number) => void;
}

export const Banner = React.memo(
  ({ data, onBannerClick, currentIndex, onIndexChange }: BannerProps) => {
    return (
      <Swiper
        className="banner-swiper"
        onRealIndexChange={(swiper: any) =>
          onIndexChange(swiper.realIndex || 0)
        }
        loop={data && data.length > 1}
        autoplay={{ delay: 4000 }}
        modules={[Autoplay]}
      >
        {data.map((item, i) => (
          <SwiperSlide key={i}>
            <BannerWrapper onClick={() => onBannerClick(item.description)}>
              <div className="banner">
                <ImageContainer ratio={360 / 210} alt="배너" src={item.url} />
              </div>
            </BannerWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
);
